.containerTabela {
    padding: 0 20px;  /* Adiciona espaço em ambos os lados */
    width: 100%;
    max-height: 400px; /* Define uma altura máxima para permitir rolagem */
    overflow-y: auto; /* Habilita a rolagem vertical */
    overflow-x: auto; /* Permite rolagem horizontal caso necessário */
    margin-bottom: 20px; /* Adiciona espaço entre as divs */
}

.tabelaIndividuais {
    max-height: 400px; /* Define altura máxima */
    overflow-y: auto; /* Permite rolagem vertical independente */
    display: block; /* Necessário para sticky funcionar */
    position: relative; /* Garante alinhamento correto */
}

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Mantém o layout estável */
}

thead {
    position: sticky;
    top: 0;
    background-color: #fff; /* Mantém a cor ao fixar */
    z-index: 10; /* Garante que o cabeçalho fique acima do conteúdo */
}

th, td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
    min-width: 120px; /* Aumenta largura mínima das colunas */
}

th {
    background-color: #f2f2f2;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .containerTabela {
        max-height: 300px; /* Reduz altura em telas menores */
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    table {
        min-width: 600px; /* Garante que a tabela não se contraia demais */
    }
}
