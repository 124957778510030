.dashboardContainer {
    margin-top: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    margin: 20px auto;
}

.dashboardContainer h3 {
    color: black;
}

.cardsContainer {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.card {
    flex: 1;
    min-width: 200px;
    padding: 20px;
    background: #f4f4f4;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Ajustada regra para remover marcadores de lista */
.listaAtletas ul, .listaAtletas li {
    list-style: none;
    margin: 0;
    padding: 0;
}
