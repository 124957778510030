.resultadosContainer {
  padding: 2rem;
  font-family: 'Roboto', sans-serif;
  color: #333;
  min-height: 100vh;
}

.resultadosContainer h1 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  color: #fff !important; /* Changed to white */
}

.resultadosContainer h1, .resultadosContainer h2{
  text-align: center;
  color: #fff;
}

.titulo h1 {
  size: 4rm;
}

.titulo h2 {
  size: 3rm;
} 

.provaContainer {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s;
}

.provaContainer:hover {
  transform: translateY(-5px);
}

.provaContainer h2 {
  margin-bottom: 1rem;
  border-bottom: 2px solid #3498db;
  padding-bottom: 0.5rem;
  color: #ffffff; 
}

.bateriaContainer {
  background-color: #f7fbfc;
  border-left: 4px solid #3498db;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.bateriaContainer h3 {
  margin-bottom: 0.75rem;
  font-size: 1.25rem;
  color: #2c3e50;
}

.nadador {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px dashed #ddd;
}

.nadador:last-child {
  border-bottom: none;
}

.nadador span {
  font-size: 0.95rem;
  margin-right: 1rem;
}

@media (max-width: 768px) {
  .nadador {
    flex-direction: column;
  }
}

.tabelaPersonalizada table {
  text-align: center !important;
}

.tabelaPersonalizada tbody tr:nth-child(odd) {
  background-color: #fababa !important;
}

.tabelaPersonalizada tbody tr:nth-child(even) {
  background-color: #9ecdf1 !important;
}

.tabelaPersonalizada thead {
  background-color: #FF5733 !important; /* New background color */
  font-size: 1.2rem !important;          /* Increased font size */
  text-align: center !important;
  border-bottom: 3px solid #1558b0 !important;
}

.tabelaPersonalizada thead th {
  text-align: center !important;
}

.tabelaPersonalizada tbody tr {
  transition: background-color 0.3s ease !important;
}

.tabelaPersonalizada tbody td {
  text-align: center !important;
}

/* Optional: add a subtle hover effect on rows */
.tabelaPersonalizada tbody tr:hover {
  background-color: #cce4f7 !important;
}

.tabelaPersonalizada table th {
  background-color: #f86f50 !important; /* New background color */
  color: #fff !important;              /* New text color */
  font-size: 18px;
}

.tabelaClassificacaoFinal table,
.tabelaResultadosBalizamento table,
.tabelaClassificacaoCategoria table {
  text-align: center !important;
}

.classificacaoTabela {
  text-align: center;
}

.classificacaoTabela th, .classificacaoTabela td {
  text-align: center;
}

.loadingMessage {
  font-size: 2rem;
  color: #fff;
  text-align: center;
  margin-top: 2rem;
}

/* Styles for the new "Absoluto" tab */
.absolutoContainer {
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s;
}

.absolutoContainer:hover {
  transform: translateY(-5px);
}

.absolutoContainer h2 {
  margin-bottom: 1rem;
  border-bottom: 2px solid #964cd6;
  padding-bottom: 0.5rem;
  color: #ffffff; 
}

.absolutoTabela {
  text-align: center;
}

.absolutoTabela th, .absolutoTabela td {
  text-align: center;
}

.cardContainer h3 {
  color: #000 !important;
}

.cardContainer {
  cursor: pointer;
}
