.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    /* Garante que o conteúdo se ajuste corretamente */
    width: 100%;
    min-height: auto;
}

.container h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    display: block;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.cardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    /* Garante que os cards não fiquem escondidos */
    align-items: stretch;
    gap: 16px;
    width: 100%;
}
