.tabela {
    width: calc(100% - 40px); /* largura ajustada conforme as margens */
    border-collapse: collapse;
    margin: 20px auto; /* margem vertical 20px e centralização horizontal */
  }
  
  .tabela th, .tabela td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .tabela th {
    background-color: #f2f2f2;
    text-align: center;
  }
