.card {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px;
    transition: transform 0.2s;
    
    /* Melhor ajuste de tamanho para diferentes telas */
    max-width: 250px;
    min-width: 200px;
    width: 100%;

    /* Flexbox compatível com iOS */
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    
    /* Garante que o clique funcione no iPhone */
    cursor: pointer;
}

.card:hover {
    transform: scale(1.05);
}

.card h3 {
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
    width: 100%;
}

.card p {
    font-size: 1em;
    margin: 5px 0;
    color: #333333;
    text-align: center;
}

.card a {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #007BFF;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.2s;
}

.card a:hover {
    background-color: #0056b3;
}

.endereco {
    margin-top: auto;
}
