.resultadosEntrada.resultadosEntrada {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    margin: 0 auto; /* Centralizar o container */
}

.opcoesContainer.opcoesContainer, .listagemContainer.listagemContainer {
    width: 80%;
    margin: 0 auto;
    text-align: center;
}

.bateriaContainer.bateriaContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.bateriaContainer {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%; /* Altera para preencher 100% do container pai */
}

.bateriaContainer.bateriaContainer h2 {
    margin-bottom: 10px;
}
.formulario.formulario {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formulario.formulario div {
    display: flex;
    justify-content: center;  /* Centraliza o conteúdo horizontalmente */
    align-items: center;
    margin-bottom: 10px;
}

.formulario.formulario label {
    margin-right: 10px;
}

.formulario.formulario select {
    text-align: center; /* Centraliza o texto dentro do select */
}

.btnSalvar.btnSalvar {
    margin-top: 20px;
}

.nadadorContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.nadadorContainer {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

/* Adicione a regra para alternar o fundo dos elementos .nadadorContainer */
.nadadorContainer:nth-child(odd) {
    background-color: #ffffff; /* Fundo claro */
}

.nadadorContainer:nth-child(even) {
    background-color: #c8b1b1; /* Fundo alternado */
}

.nadadorNome {
    flex: 1;
    text-align: left;
    font-weight: bold;
    color: #333;
    font-size: 24px; /* Adicionado: define o tamanho do texto para 24px */
}

.nadadorNome {
    flex: 1;
    font-weight: bold;
    color: #333;
}

.nadadorInput {
    flex: 1;
    text-align: right;
    margin-right: 10px;
}

.nadadorInput {
    flex: 0.5;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin-left: 8px;
}

.nadadorInput:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}

.inputSalvo {
    border: 4px solid green;  /* Mantém a borda */
    padding: 2px;             /* Menor padding para input mais compacto */
    font-size: 24px;          /* Tamanho do texto */
    width: 120px;             /* Largura fixa para manter o input pequeno */
    box-sizing: border-box;   /* Inclui padding e borda no cálculo da largura */
}

label {
    margin-left: 10px;
    font-weight: bold;
    color: #333;
}

.provaSalva {
    color: purple;
    font-weight: bold;
    margin-bottom: 10px;
}
