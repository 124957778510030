/* Respostas menores */
@media (max-width: 768px) {
    .cadastroContainer {
        width: 90%;
        /* Aumenta a largura em telas menores */
        padding: 15px;
    }
}


/* rankings.module.css */
.btnFiltrar {
    background-color: #007bff;
    font-weight: bolder;
    width: 30%;
    height: 50px;
    padding: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 20px;
}

.btnFiltrar:hover {
    background-color: #0056b3;
}


/* Estilo para o título */
h1 {
    font-size: 4rem;
    color: #e3e9f1;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}

/* Estilos para a tabela */
.rankings table {
    width: 70%;
    margin: 20px auto;
    border-collapse: collapse;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
}

/* Cabeçalho da tabela */
.rankings th {
    background-color: #1a73e8;
    color: #ffffff;
    font-weight: bold;
    padding: 12px;
    text-align: left;
    font-size: 1rem;
}

/* Linhas da tabela */
.rankings td {
    padding: 10px;
    text-align: left;
    font-size: 1rem;
    color: black;
    border-bottom: 1px solid #ddd;
}

/* Alternância de cor nas linhas da tabela */
.rankings tr:nth-child(even) {
    background-color: #9bd5e8;
}

.rankings tr:nth-child(odd) {
    background-color: #ffffff;
}

/* Estilo de foco ao passar o mouse sobre uma linha */
.rankings tr:hover {
    background-color: #6de95f;
}



.filtroContainer {
    /* CAMPO DE FORMULARIO COMPLETO */
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


/* Estilo para inputs e selects */

.filtroContainer select {
    /* Tamanho e padding */
    height: 50px;
    width: 400px; /* Ajuste o valor conforme necessário */
    max-width: 100%; /* Garante que o select não ultrapasse a largura do container */
    padding: 10px 3px;
    /* Fontes e cores */
    font-size: 20px;
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50px;
    text-align: center;

    -webkit-appearance: none;
-moz-appearance: none;
appearance: none;

    /* Aparência ao passar o mouse */
    &:hover {
        border-color: #999;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    }
}
